
#app {
  color: #374054;
  font-family: Arial, Helvetica, sans-serif;
}

button.btn-light {
  color: #fff;
  background-color: #99c7d3;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

button.btn-light:hover {
  background-color: #8eb9c5;
}

button.loading {
  font-family: Arial, Helvetica, sans-serif;
}

/* Question Title */
.card-head {
  color: #fff;
  background-color: #99c7d3;
}
